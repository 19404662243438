//
// Timeline 5
//

.timeline.timeline-5 {
    .timeline-items {
        margin: 0;
        padding: 0;

        .timeline-item {
            margin-left: 0;
            padding: 0;
            position: relative;

            .timeline-media {
                position: absolute;
                top: 0;
                border-radius: 100%;
                width: get($timeline-5-config, media-size);
                height: get($timeline-5-config, media-size);
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                line-height: 0;

                i {
                    font-size: 1.4rem;
                }

                .svg-icon {
                    @include svg-icon-size(24px);
                }
            }

			.timeline-desc {
				padding: 9px 0 0 44px;

				&::before{
					content: '';
					position: absolute;
					width: 4px;
					height: calc(100% - 32px);
					background-color: $gray-200;
					border-radius: 6px;
					top: 40px;
					left: 16px;
				}

                // Theme colors
                @each $name, $color in $theme-colors {
                    &.timeline-desc-#{$name} {
                        &::before {
                            background-color: $color;
                        }
                    }

                    &.timeline-desc-light-#{$name} {
                        &::before {
                            background-color: theme-light-color($name);
                        }
                    }
                }
			}

            &:last-child {
                border-left-color: transparent;
                padding-bottom: 0;
            }
        }
    }
}
