//
// Progress
//

.progress {
    &.progress-xs {
        height: $progress-height-xs;

        .progress-bar {
        	border-radius: $border-radius-sm;
        }
    }

    &.progress-sm {
        height: $progress-height-sm;

        .progress-bar {
        	border-radius: $border-radius-sm;
        }
    }

    &.progress-lg {
        height: $progress-height-lg;

       	.progress-bar {
        	border-radius: $border-radius-lg;
        }
    }
}

// Vertical Position
.progress-vertical {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .progress {
        height: 100%;
        @include border-radius($border-radius);
        display: flex;
        align-items: flex-end;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }

        .progress-bar {
            width: 8px;
            @include border-radius($border-radius);
        }
    }
}
