//
// Fixes
//


@mixin fix-fixed-position-lags() {
	// webkit hack for smooth font view on fixed positioned elements
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;
}

@mixin fix-animation-lags() {
    transform: translateZ(0);
    -webkit-transform-style: preserve-3d;
}
