HTML CSS BabelResult Skip Results Iframe
EDIT ON
/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  height: 400px;
  width: 100%;
  border-radius: 40px;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}

@media screen and (max-width: 991px){
  .responsive-login{
    display: flex;
    max-width: 100% !important;
    flex-direction: column;
    background-image: linear-gradient(indigo,indigo,purple);
  }

  /* .part-2{
    margin-top: 0px !important;
  } */
}

@media screen and (min-width: 992px) and (max-width: 999px){
  .responsive-login{
    display: flex;
    max-width: 500px !important;
    flex-direction: column;
    background-image: linear-gradient(indigo,indigo,purple);
  }

  /* .part-2{
    margin-top: 90px;
  } */
}

@media screen and (min-width: 1000px) {
    .responsive-login{
      /* width: 700px !important; */
      max-width: 530px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }

    /* .part-2{
      margin-top: 90px;
    } */
}

@media screen and (min-width: 1050px) {
    .responsive-login{
      /* width: 700px !important; */
      max-width: 570px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }

    /* .part-2{
      margin-top: 90px;
    } */
  }
  
  @media screen and (min-width: 1150px) {
    .responsive-login{
      /* width: 700px !important; */
      max-width: 650px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }

    /* .part-2{
      margin-top: 90px;
    } */
  }
  
  @media screen and (min-width: 1250px) {
    .responsive-login{
      /* width: 850px !important; */
      max-width: 750px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }

    /* .part-2{
      margin-top: 90px;
    } */
  }
  
  @media screen and (min-width: 1350px) {
    .responsive-login{
      /* width: 850px !important; */
      max-width: 900px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }

    /* .part-2{
      margin-top: 90px;
    } */
  }