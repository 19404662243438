//
//  Toggle
//

.toggle-off {
    .toggle-off-item {
        display: none !important;
    }
}

.toggle-on {
    .toggle-on-item {
        display: none !important;
    }
}
